.jugador-container {
    display: flex;
}

.card {
    border: .07rem solid black;
    border-radius: .65rem;
    width: 12rem;
    min-height: 340px;
    padding: .3rem .65rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: .3rem .3rem .65rem gray; 
    background-color: white;    
}
.cardHeader {
    border: .07rem solid black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;           
}
.cardHeader div:first-child, .cardHeader div:last-child {
    padding: .2rem .2rem;
    width: 1rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.cardHeader div:first-child {
    border-right: .07rem solid black;
}
.cardHeader div:last-child {
    border-left: .07rem solid black;
}

.cardHeader div img {
    height: 2rem;
    margin-top: -0.25rem;
    margin-left: -2rem;
    position: absolute;
}

.img {
    display: flex;
    justify-content: center;

}

.nombre {
    display: flex;
    justify-content: center;
    font-weight: bold;
}

.atributos {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.atributos > div {
    padding: 0rem 0.3rem;
    border: .07rem solid black;
    border-bottom: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.atributos:last-child {
    border-bottom: .07rem solid black;
}

.atributos > div:hover {
    background-color: teal;
    cursor: pointer;
}

.anulado {
    background-color: darkred;
    cursor: not-allowed; 
}

.roja:hover, .amarilla:hover {
    cursor: pointer;
}

.roja {
    background-color: red;
}

.amarilla {
    background-color: yellow;
}