.chat {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    background-color: #ddd;
    border: 1px solid black;
    border-radius: 10px;    
    padding: 10px 5px;
    margin: 0px 10px;
    min-width: 400px;
    max-width: 600px;
    box-shadow: 5px 5px 10px gray; 
}

.Adversario, .Jugador, .Dealer {
    font-family: Arial, Helvetica, sans-serif;
    padding: 3px 15px;
    margin: 6px 10px;  
}

.Adversario {
    align-self: flex-end;
    background-color: #d9bfad;
    border-radius: 10px 10px 0px 10px;
}

.Jugador {
    align-self: flex-start;
    background-color:#add9b9;
    border-radius: 10px 10px 10px 0px;
}

.Dealer {
    align-self: center;
    background-color:#ccd185;
    border-radius: 10px;
}