.empate-container {
    display: flex;
    border: .07rem solid black;
    border-radius: .65rem;
    flex-direction: column;
}

.cartas-empate-container {
    display: flex;
    flex-direction: row;
}

.card-empate {
    border: .07rem solid black;
    border-radius: .65rem;
    width: 5rem;
    padding: .3rem .65rem;
    margin: .65rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: .3rem .3rem .65rem gray; 
    background-color: white;    
    font-size: .3rem;  
}

.cardHeader-empate {
    border: .07rem solid black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;           
}
.cardHeader-empate div:first-child, .cardHeader-empate div:last-child {
    padding: .2rem .2rem;
    width: 1rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.cardHeader-empate div:first-child {
    border-right: .07rem solid black;
}
.cardHeader-empate div:last-child {
    border-left: .07rem solid black;
}

.cardHeader-empate div img {
    height: .6rem;
    margin-top: 0.5%;
    margin-left: -1%;
    position: absolute;
}

.img-container-empate {
    
    /* border: .07rem solid black; */
    display: flex;
    justify-content: center;
}

.img-container-empate img {
    width: 75px;
    height: 100%;  
}

.nombre {
    display: flex;
    justify-content: center;
    font-weight: bold;
}

.atributos-empate {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.atributos-empate > div {
    padding: 0rem 0.3rem;
    border: .07rem solid black;
    border-bottom: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.atributos-empate:last-child {
    border-bottom: .07rem solid black;
}